.nav_bar {
    background: #292F34;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_bar > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
    width: 1440px;
}

.nav_bar .right_section .connect_button {
    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    border-radius: 50px;

    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 10px 14px;
}

/* connected_button */
.nav_bar .right_section .connected_button {
    background: #1B1B1B;
    border-radius: 27.5px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    text-transform: unset;
    z-index: 0;
    cursor: pointer;
}

.nav_bar .right_section .connected_button .profile_details {
    text-align: right;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.nav_bar .right_section .connected_button .profile_details .balance {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: right;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
}

.nav_bar .right_section .connected_button .profile_details .hash_text {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: right;
    font-feature-settings: 'zero' on;
    color: #B7B7B7;
}

.nav_bar .right_section .connected_button .profile_image {
    width: 34px;
    height: 34px;
    border-radius: 50px;
    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
}

/* account_info */
.account_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.account_info .mobile_view {
    display: none;
}

.account_info .desk_view {
    display: block;
}

/* profile popover */
.profile_popover > div:nth-child(3) {
    background: #1c2125;
    border: 1px solid #232a30;
    border-radius: 10px;
}

.profile_popover .profile_popover_items {
    width: 180px;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
}

.profile_popover .profile_popover_items button {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding: 6px 10px;
    border-radius: 5px;
    text-transform: capitalize;
    width: 100%;
}

.profile_popover .profile_popover_items .logout {
    background: #eb5757;
    color: #fff;
}

.profile_popover .profile_popover_items .account_details {
    display: none;
}

@media (max-width: 550px) {
    .nav_bar > div {
        padding: 10px 20px;
        height: 70px;
    }
}

@media (max-width: 500px) {
    .account_info .mobile_view {
        display: flex;
    }

    .account_info .desk_view {
        display: none;
    }

    .account_info .mobile_view .menu svg {
        fill: #FFFFFF;
    }

    .profile_popover .profile_popover_items .account_details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;
    }

    .profile_popover .profile_popover_items .account_details .section1 {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        background: #141414;
        padding: 8px 10px;
        border-radius: 5px;
    }

    .profile_popover .profile_popover_items .account_details .section1 .balance {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-align: right;
        font-feature-settings: 'zero' on;
        color: #FFFFFF;
    }

    .profile_popover .profile_popover_items .account_details .section1 .hash_text {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: right;
        font-feature-settings: 'zero' on;
        color: #B7B7B7;
    }

    .profile_popover .profile_popover_items .account_details .connect_button {
        background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
        color: #FFFFFF;
    }
}
