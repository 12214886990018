html, body, #root, .app, .main_content {
    min-height: 100% !important;
    height: 100%;
}

.app {
    height: 100%;
}

* {
    box-sizing: border-box;
}

body > iframe {
    display: none;
}

body {
    margin: 0;
    text-align: center;
    color: #351986;
    background: #000;
    overflow: hidden;
}

.nav_bar + .content_div {
    padding-top: 80px;
}

p {
    margin: unset;
}

.hash_text {
    max-width: 100px;
    display: flex;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #B7B7B7;
}

.hash_text .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -5px;
}

.main_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_content .content_div {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 40px 10px;
    width: 1440px;
    height: 100%;
}

.dark_mode.main_content .content_div {
    background: #140739;
}

.main_content .content_div > div {
    height: 100%;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/*QR model*/
body #cosmostation-wc-QRModal > div {
    z-index: 1301;
}

body #cosmostation-wc-QRModal > div > div {
    color: #1b1b1b;
}

/* scroll bar */
.app .scroll_bar {
    overflow: auto;
}

html::-webkit-scrollbar,
.data_table > div > div:nth-child(2)::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 13px;
    background: #000;
}

.data_table > div > div:nth-child(2)::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #757575;
    border: 4px solid transparent;
    border-radius: 8px;
    height: 56px;
}

@media (max-width: 1440px) {
    .main_content .content_div {
        width: 100%;
    }
}

@media (max-width: 880px) {
    .main_content .content_div {
        padding: 40px 20px;
    }
}

@media (max-width: 520px) {
    .main_content .content_div {
        padding: 60px 20px 40px;
    }
}
