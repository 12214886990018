.side_panel > div:nth-child(3) {
    width: 400px;
    border-radius: 10px 0 0 10px;
    background: #23242F;
}

.side_panel .section1 {
    padding: 20px;
}

.side_panel .section1 .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.side_panel .section1 .header h2 {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    text-align: left;
}

.side_panel .section1 .header button {
    border: 1px solid #c95379;
    border-radius: 50px;
    width: 25px;
    height: 26px;
    min-width: 26px;
}
.side_panel .section1 .header button svg {
    fill: #c95378;
    width: 22px;
    height: 22px;
}


.side_panel .section1 .address_section {
    border-radius: 10px;
    border: 1px solid #3B3C4E;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 14px;
}

.side_panel .section1 .address_section .hash_text {
    color: #8B8CA7;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    max-width: 70%;
}

.side_panel .section1 .address_section .copy_button {
    background: unset;
    box-shadow: unset;
}

.side_panel .section1 .amount_section {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.side_panel .section1 .amount_section > span {
    margin-left: 10px;

    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;

    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.side_panel .section1 .amount_section > p {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;

    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.side_panel .section1 .amount_section > p > span {
    font-size: 20px;
}

.side_panel .section1 .test_tokens button {
    border-radius: 10px;
    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);

    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    text-transform: capitalize;
    padding: 10px 20px;
    width: 100%;
}

/* section2 */
.side_panel .section2 {
    border-radius: 10px;
    background: #1A1B23;
    margin: 0 20px 20px;
}

.side_panel .section2 .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 4px 16px;
    border-bottom: 1px solid #23242F;
}

.side_panel .section2 .header h2 {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.side_panel .section2 .header button {
    color: #F44554;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    text-transform: capitalize;
}

.side_panel .section2 .header button svg {
    margin-right: 10px;
}

.side_panel .section2 .row {
    border-top: 1px solid #23242F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
}

.side_panel .section2 .row:first-child {
    border-top: 0;
}

.side_panel .section2 .row > div {
    color: #8B8CA7;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.side_panel .section2 .row > div > p {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    border-radius: 5px;
    background: #8B8CA7;

    margin-left: 10px;
    padding: 2px 8px;
}

.side_panel .section2 .row > svg {

}

/* Section3 */
.side_panel .section3 {

}

.side_panel .section3 .tx_type {
    margin-top: 20px;
    padding: 0 20px;
}

.side_panel .section3 .tx_type > p {
    color: #696B8C;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 8px;
    text-align: left;
}

.side_panel .section3 .tx_type > div {
    width: 100%;
}

.side_panel .section3 .tx_type > div > button {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    color: #FFF;
    text-align: center;
    width: 50%;
    padding: 12px;
    text-transform: capitalize;
}

.side_panel .section3 .tx_type > div > button:first-child {
    border-radius: 10px 0 0 10px;
    border: 1px solid #3b3c50;
}

.side_panel .section3 .tx_type > div > button:last-child {
    border-radius: 0 10px 10px 0;
    border: 1px solid #3b3c50;
}

.side_panel .section3 .tx_type > div button.active {
    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    border: 1px solid #3b3c50;
}

.side_panel .section3 .amount_deposit {
    padding: 14px 20px 10px;
}

.side_panel .section3 .amount_deposit .start {
    color: #696B8C;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.side_panel .section3 .amount_deposit .start > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.side_panel .section3 .amount_deposit .start > div button {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    border-radius: 8px;
    text-transform: capitalize;
    border: 1px solid #8b5da1;
    background: unset;
    padding: 3px 8px;
    max-width: initial;
    min-width: auto;
    margin-left: 4px;

    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.side_panel .section3 .amount_deposit .end {
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;

    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.side_panel .section3 .amount_deposit .end > span {
    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.side_panel .section3 .amount_deposit .fields {
    border-radius: 10px;
    border: 1px solid #3B3C4E;
    background: #22232E;

    display: flex;
    align-items: center;
}

.side_panel .section3 .amount_deposit .fields > .select_field_parent {
    width: 50%;
}

.side_panel .section3 .amount_deposit .fields > .select_field_parent .select_field .select__control {
    border-radius: 10px 0 0 10px;
    background: unset;
    border: unset;
}

.side_panel .section3 .amount_deposit .fields > .select_field_parent .select_field .select__value-container {
    padding: 4px 0 4px 10px;
}

.side_panel .section3 .amount_deposit .fields > .select_field_parent .select_field .label > svg {
    margin-right: 4px;
}
.side_panel .section3 .amount_deposit .fields > .select_field_parent .select_field .label > img {
    margin-right: 4px;
    width: 20px;
    height: 20px;
}
.side_panel .section3 .amount_deposit .fields > .select_field_parent .select_field .label {
    margin-top: 0;
}

.side_panel .section3 .amount_deposit .fields > .text_field {
    width: 50%;
}

.side_panel .section3 .amount_deposit .fields > .text_field > div {
    border-radius: 0 10px 10px 0;
    background: unset;
}

.side_panel .section3 .amount_deposit .fields > .text_field input {
    padding: 8px 10px;
    text-align: right;

    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.side_panel .section3 .address {
    padding: 10px 20px 20px;
}

.side_panel .section3 .address .head {
    color: #696B8C;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
}

.side_panel .section3 .address .text_field {
    width: 100%;
}

.side_panel .section3 .address .text_field > div {
    border-radius: 10px;
    border: 1px solid #3B3C4E;
    background: #22232E;
}

.side_panel .section3 .address .text_field > div > div {
    margin-top: 0;
}

.side_panel .section3 .address .text_field input {
    padding: 10px;
    text-align: right;

    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.side_panel .section3 .address .text_field .your_address {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

/* actions */
.side_panel .actions {
    margin-bottom: 20px;
    padding: 0 20px;
}

.side_panel .actions button {
    border-radius: 10px;
    background: linear-gradient(48deg, #E15121 0%, #FF5B25 20.36%, #FF5B25 34.75%, #FF5B25 51.03%, #FF5B25 100%);

    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    padding: 12px 20px;
    width: 100%;
    text-transform: capitalize;
}

.side_panel .actions button:disabled {
    opacity: 0.5;
}

/* deposite Dialog */
.deposite_dialog > div:nth-child(3) > div {
    background: rgb(24 27 31);
    border-radius: 20px;
    padding: 40px;
    width: 550px;
    max-width: 550px !important;
}

.deposite_dialog .deposite_dialog_content > h2 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
}

.deposite_dialog .deposite_section1 {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.deposite_dialog .deposite_section1 > span {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 15px;
}

.deposite_dialog .ibc_transfer_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deposite_dialog .ibc_transfer_section > div {
    background: rgba(33, 37, 42, 0.5);
    border: 1px solid #292929;
    box-sizing: border-box;
    border-radius: 10px;
    width: 200px;
    text-align: left;
    padding: 5px 10px;
}

.deposite_dialog .ibc_transfer_section > div > span {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #828282;
}

.deposite_dialog .ibc_transfer_section > div .hash_text {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    max-width: 160px;
}

.deposite_dialog .deposite_section2 {
    margin-top: 50px;
}

.deposite_dialog .deposite_section2 .deposite_section2_header {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deposite_dialog .deposite_section2 .deposite_section2_header .balance {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #1DA1F2;
}

.deposite_dialog .deposite_section2 .amount_field {
    width: 100%;
}

.deposite_dialog .deposite_section2 .amount_field > div > div:nth-child(1) {
    margin: 0;
}

.deposite_dialog .deposite_section2 .amount_field .token_start {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    margin-left: 10px;
    border-right: 1px solid #fff;
    padding-right: 20px;
}

.deposite_dialog .deposite_section2 .amount_field .amount_end {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #1DA1F2;
    cursor: pointer;
}

.deposite_dialog .deposite_section2 .amount_field input {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

.deposite_dialog .deposite_button {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    background: linear-gradient(89.99deg, #F44554 0.42%, #3271E1 104.54%);
    border-radius: 5px;
    margin-top: 50px;
    padding: 12px 20px;
    text-transform: capitalize;
    width: 100%;
}

.deposite_dialog .deposite_button:disabled,
.activity_table .deposite_value:disabled {
    opacity: 0.5;
}

.deposite_dialog .deposite_button > span {
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* confirm_ibc_dialog */
.confirm_ibc_dialog > h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #E7E7E7;
    margin: 20px 0 10px 0;
}

.confirm_ibc_dialog > div .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.confirm_ibc_dialog > div .row .label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #B7B7B7;
}

.confirm_ibc_dialog > div .row .value {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #F2F2F2;
}

.confirm_ibc_dialog > div .row .token_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.confirm_ibc_dialog > div .row .token_info img {
    margin-right: 10px;
    width: 20px;
}

.confirm_ibc_dialog > div .row .hash_text.address {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #F2F2F2;
    max-width: 200px;
}


.confirm_ibc_dialog .confirm_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.confirm_ibc_dialog .confirm_actions > button {
    border-radius: 5px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    /*background: #1B1B1B;*/
    width: 49%;
    height: 40px;
    text-transform: uppercase;
    margin-top: 50px;
}

.deposite_dialog > div:nth-child(3) > div .ledger_note.warning {
    color: red;
    margin: 40px 0;
}

.deposite_dialog .cancel_button {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    width: 100%;
    padding: 12px;
    text-transform: capitalize;
}

.deposite_dialog .cancel_button > span {
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.deposite_dialog .cancel_button:before {
    content: '';
    position: absolute;
    background: #202429;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 5px;
}

.deposite_dialog .cancel_button:after {
    content: '';
    background: linear-gradient(91.79deg, #FAC01E 3.15%, #FC762A 85.66%);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -2;
    border-radius: 5px;
}

.deposite_dialog > div:nth-child(3) > div .ledger_note {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #B7B7B7;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
}

.deposite_dialog > div:nth-child(3) > div .wallet_connect_note {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #B7B7B7;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.side_panel .address_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #3B3C4E;
    background: #22232E;
    padding: 20px;
}

.side_panel .address_details .hash_text {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 110px;
}

.side_panel .mobile_view {
    display: none;
}

@media (max-width: 880px) {
    .side_panel .mobile_view {
        display: unset;
        padding: 0 20px;
    }

    .side_panel .mobile_view .connect_with_us {
        margin: unset;
    }
}

@media (max-width: 425px) {
    .deposite_dialog > div:nth-child(3) > div {
        padding: 20px;
    }

    .deposite_dialog .ibc_transfer_section {
        flex-direction: column;
    }

    .deposite_dialog .ibc_transfer_section > div {
        width: 100%;
    }

    .deposite_dialog .ibc_transfer_section > img {
        margin: 10px 0;
    }

    .deposite_dialog .ibc_transfer_section > div .hash_text {
        font-size: 14px;
    }

    .deposite_dialog .deposite_section2 .deposite_section2_header {
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
    }

    .deposite_dialog .deposite_section2 .deposite_section2_header .balance {
        font-size: 13px;
        margin-top: 6px;
    }

    .activity_table .token_value {
        font-size: 14px;
    }

    .activity_table .amount_value {
        font-size: 14px;
    }

    .activity_table .token_value img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    .activity_table .deposite_value {
        font-size: 12px;
        padding: 6px 10px;
    }

    .deposite_dialog .deposite_dialog_content > h2 {
        font-size: 20px;
    }

    .deposite_dialog .deposite_section1 > span {
        font-size: 16px;
    }

    .deposite_dialog .ibc_transfer_section > div > span {
        font-size: 14px;
    }

    .deposite_dialog .deposite_section2 .amount_field .token_start {
        font-size: 14px;
    }

    .deposite_dialog .deposite_section2 .amount_field .token_start_icon {
        width: 20px;
    }

    .deposite_dialog .deposite_button {
        font-size: 16px;
        padding: 10px;
    }
}

@media (max-width: 425px) {
    .side_panel > div:nth-child(3) {
        width: 100%;
    }
}
