.dialog.connect_dialog > div:nth-child(3) > div {
    max-width: 500px;
    width: 100%;
    background: linear-gradient(180deg, #0E151B 0%, #000000 100%);
    border-radius: 20px;
    margin: unset;
    padding: 50px 0;
}

.dialog.connect_dialog > div .dialog_content {
    padding: 40px;
}

.connect_dialog h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 0 0 35px 0;
}

.connect_dialog .wallets_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.connect_dialog .wallets_section .wallet_button {
    background: rgba(33, 38, 43, 0.4);
    border-radius: 10px;
    margin-bottom: 16px;
    width: 330px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.connect_dialog .wallets_section .wallet_button:hover {
    background: rgba(75, 75, 75, 0.5);
    /*border: 1px solid #292929;*/
    border-radius: 10px;
}

.connect_dialog .wallets_section .wallet_button:last-child {
    margin-bottom: 0;
}

.connect_dialog .wallets_section .wallet_button button:first-child {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #828282;
    background: unset;
    box-shadow: unset;
    text-transform: capitalize;
    flex: 1;
    justify-content: flex-start;
    padding: 15px 20px;
}

.connect_dialog .wallets_section .wallet_button button:first-child img,
.connect_dialog .wallets_section .wallet_button button:first-child svg {
    width: 30px;
    margin-right: 10px;
}

.connect_dialog .wallets_section .wallet_button:hover button:first-child {
    color: #FFFFFF;
}

.connect_dialog .wallets_section .wallet_button .download_button {
    min-width: unset;
    margin: 0 10px;
}

.connect_dialog .wallets_section .wallet_button .download_button svg {
    fill: #828282;
}

.connect_dialog .wallets_section .wallet_button:hover .download_button svg {
    fill: url(#paint0_linear_9399_173783);
}

/*.connect_dialog .buttons_div {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/

/*.connect_dialog .buttons_div button {*/
/*    background: rgba(33, 38, 43, 0.4);*/
/*    border-radius: 10px;*/

/*    font-family: 'Blinker', sans-serif;*/
/*    font-weight: 400;*/
/*    font-size: 20px;*/
/*    line-height: 100%;*/
/*    color: #FFFFFF;*/
/*    justify-content: flex-start;*/
/*    padding: 14px 20px;*/
/*    box-shadow: 0 4px 20px rgb(0 0 0 / 25%);*/
/*    text-transform: unset;*/
/*    min-width: 240px;*/
/*    z-index: 0;*/
/*}*/

/*.connect_dialog .buttons_div button:disabled {*/
/*    opacity: 0.6;*/
/*}*/

/*.connect_dialog .buttons_div button:hover {*/
/*    background: #222222;*/
/*}*/

/*.connect_dialog .buttons_div button:hover:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    background: #222222;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    z-index: -1;*/
/*    border-radius: 10px;*/
/*}*/

/*.connect_dialog .buttons_div button:hover:after {*/
/*    content: "";*/
/*    background: linear-gradient(181deg, #2FCDCF 14.6%, #966AFB 82.44%);*/
/*    position: absolute;*/
/*    top: -1px;*/
/*    left: -1px;*/
/*    width: calc(100% + 2px);*/
/*    height: calc(100% + 2px);*/
/*    z-index: -2;*/
/*    border-radius: 10px;*/
/*}*/

/*.connect_dialog .buttons_div button > span:first-child {*/
/*    justify-content: flex-start;*/
/*}*/

/*.connect_dialog .buttons_div button img {*/
/*    width: 30px;*/
/*    margin-right: 10px;*/
/*}*/

/*.connect_dialog .buttons_div .keplr_button,*/
/*.connect_dialog .buttons_div .cosmostation_button,*/
/*.connect_dialog .buttons_div .wallet_connect_button {*/
/*    margin-bottom: 20px;*/
/*}*/

/*.connect_dialog .buttons_div .wallet_connect_button:hover:after {*/
/*    background: #3496FE;*/
/*}*/

@media (max-width: 768px) {
    .dialog.connect_dialog > div:nth-child(3) > div {
        max-width: 90%;
        width: 90%;
        min-width: 90%;
        margin: 10px;
    }
}
